<template>
  <div v-if="shouldShowAlert" class="alert" :class="alertType">
      <div class="alert_message">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          {{ alertMessage }}
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['userData', 'Global_Settings']),

    currentPage() {
      return this.$route.path;
    },

    isUserLoggedIn() {
      return !!this.userData;
    },

    isShopper() {
      return this.userData?.User_Role === 'SendSm1 Shopper';
    },

    isCustomer() {
      return this.userData?.User_Role === 'SendSm1 Customer';
    },

    isVerifiedShopper() {
      return this.isShopper && this.userData?.Account_Verified === 'Yes';
    },

    shopperAlert() {
      return this.Global_Settings?.Global_Settings_Shopper_Alert || {};
    },

    customerAlert() {
      return this.Global_Settings?.Global_Settings_Customer_Alert || {};
    },

    shouldShowShopperAlert() {
      if (!this.isShopper || !this.shopperAlert.Show_Alert) return false;
      if (!this.shopperAlert.Pages_To_Show.includes(this.currentPage)) return false;

      return (
        (this.isUserLoggedIn && this.shopperAlert.Show_To_logged_In_Shoppers) ||
        (!this.isUserLoggedIn && this.shopperAlert.Show_To_logged_Out_Shoppers) ||
        (this.isVerifiedShopper && this.shopperAlert.Show_To_Verified_Shoppers) ||
        (!this.isVerifiedShopper && this.shopperAlert.Show_To_Unverified_Shoppers)
      );
    },

    shouldShowCustomerAlert() {
      if (!this.customerAlert.Show_Alert) return false;
      if (!this.customerAlert.Pages_To_Show.includes(this.currentPage)) return false;

      return (
        (this.isUserLoggedIn && this.customerAlert.Show_To_Logged_In_Customers) ||
        (!this.isUserLoggedIn) // Always show customer alert to logged-out users
      );
    },

    shouldShowAlert() {
      return this.shouldShowShopperAlert || this.shouldShowCustomerAlert;
    },

    alertMessage() {
      if (!this.isUserLoggedIn) return this.customerAlert.Message; // Always use customer message for logged-out users
      if (this.shouldShowShopperAlert) return this.shopperAlert.Message;
      if (this.shouldShowCustomerAlert) return this.customerAlert.Message;
      return '';
    },

    alertType() {
      if (!this.isUserLoggedIn) return this.customerAlert.Type; // Use customer alert type for logged-out users
      if (this.shouldShowShopperAlert) return this.shopperAlert.Type;
      if (this.shouldShowCustomerAlert) return this.customerAlert.Type;
      return 'info';
    },
  },
};
</script>

<style scoped>
.alert {
  width: 100%;
  border-radius: 0px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.alert_message {
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: capitalize;
  border-bottom: 3px solid var(--primary);
}

.alert i {
  font-size: 20px;
}

.info {
  background-color: var(--base-100);
  color: red;
}

.success {
  background-color: #dff0d8;
  color: #3c763d;
}

.warning {
  background-color: #fcf8e3;
  color: #8a6d3b;
}

.error {
  background-color: #f2dede;
  color: #a94442;
}
</style>
