<template>
  <div id="app">
    <img style="display: none;" alt="Vue logo" src="./assets/logo.png">
         <app_global_settings/>
         <router-view></router-view>
  </div>
</template>

<script>
//import theme_switch from './components/theme_switch.vue';
import { mapActions } from 'vuex';
import app_global_settings from './components/app_global_settings.vue';
import './styles_inputs_and_buttons.css';
import './styles_forms.css';
import './styles_global.css';


export default {
  name: 'SendSm1',
  components: {
    app_global_settings
  },
  created() {
    this.fetchGlobalSettings();
  },
  methods: {
    ...mapActions(['fetchGlobalSettings']),
  },

}
</script>



<style>
/* Import Material Design icons CSS */
@import '~@mdi/font/css/materialdesignicons.min.css';

#app {
  text-align: center;
  margin-top: 58px;
  margin-left: auto;
  margin-right: auto;
}


*{
  padding: 0;
  margin: 0;
}


@import url('https://fonts.googleapis.com/css2?family=Anton&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Orbitron:wght@400..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Teko:wght@300..700&display=swap');

* {
  font-family: "Roboto", sans-serif;
}

body{
   background-color: var(--base-200);
   background-color: var(--base-200);

}

:root {

  --primary: #0fafff;
  --primary-dark: #017ab6;
  --secondary: #4CAF50;
  --secondary-dark: #009060;
  --accent: rgba(15, 175, 255, 0.2);
  --neutral: #3d4451;
  --base-100: #ffffff;
  --base-200: #f5f6f8;
  --base-230: #f0f0f0;
  /*
  --base-200: #f4f4f6;
  */
  --base-250: #e9eaee;
  --base-280: #e0e0e0;
  --base-300: #000000;
  --base-330: #474747;
  --base-400: #ffffff;
  --contrast: #808080;
  --contrast_light: #BEBEBE;
  --contrast_light: rgb(190, 190, 190);
  --rating_star: #ffc13b;
  --online-green: #00cc44;
  --Gradient: linear-gradient(to right, #434343 0%, black 100%);

  --font-size-small: 14px;
  --font-size-medium: 17px;
  --font-size-large: 22px;
  --font-size-extra-large: 28px;
  --font-size-top: 24px;

  --border-radius-main: 8px;
  --border-radius-main-2x: 16px;
  --border-radius-main-3x: 32px;

  --spacing-main-2x: 25px;
  --spacing-main: 20px;
  --spacing-main-mid: 15px;
  --spacing-main-half: 10px;
  --spacing-main-half-half: 5px;
  --spacing-main-half-half-half: 2.5px;

  --box-shadow-1: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  --box-shadow-2: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  --box-shadow-2: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  --box-shadow-3:  rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  --box-shadow-4:  rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

  --main_width: 100%; 
  --main_width_fixed: 90%; 

  --info-accent: rgba(15, 175, 255, 0.2);
  --info-accent-boxshadow: rgba(15, 175, 255, 0.5) 0px 0px 0px 1px;

  --error-accent: rgba(255, 0, 0, 0.1);
  --error-accent-boxshadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 1px;

  --warning-accent: rgba(255, 255, 0, 0.2);
  --warning-accent-boxshadow: rgba(255, 255, 0, 0.5) 0px 0px 0px 1px;
  
  --success-accent: rgba(42, 210, 9, 0.1);
  --success-accent-boxshadow: rgba(42, 210, 9, 0.5) 0px 0px 0px 1px;

}


</style>
