import { createStore } from 'vuex';
import { getDoc, doc, setDoc, serverTimestamp } from 'firebase/firestore/lite';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore/lite';
import { Users_Collection, Global_Settings_Collection } from './global_config';

export default createStore({
  state: {
    userData: null,
    Selected_Option: null,
    Selected_Object: null,
    Global_Settings: {
      Global_Settings_Shopper_Alert: {
        Message: '',
        Type: 'info',
        Show_Alert: false,
        Show_To_Verified_Shoppers: true,
        Show_To_Unverified_Shoppers: false,
        Show_To_Logged_In_Shoppers: true,
        Show_To_Logged_Out_Shoppers: true,
        Pages_To_Show: ['/deliver', '/account', '/account/requests'],
      },
      Global_Settings_Customer_Alert: {
        Message: '',
        Type: 'info',
        Show_Alert: false,
        Show_To_Verified_Customers: true,
        Show_To_Unverified_Customers: true,
        Show_To_Logged_In_Customers: true,
        Show_To_Logged_Out_Customers: true,
        Pages_To_Show: ['/request', '/account/requests'],
      },
    },
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
    },

    Update_Selected_Option(state, Selected_Option) {
      state.Selected_Option = Selected_Option;
    },

    Update_Selected_Object(state, Selected_Object) {
      state.Selected_Object = Selected_Object;
    },

    Update_Global_Settings(state, Global_Settings) {
      state.Global_Settings = Global_Settings;
    },
  },
  actions: {
    async fetchUserData({ commit }) {
      const auth = getAuth();
      const db = getFirestore();
      const user = auth.currentUser;
      try {
        if (user) {
          const userDoc = await getDoc(doc(db, Users_Collection, user.uid));
          if (userDoc.exists()) {
            commit('setUserData', userDoc.data());
            console.log('Fetched user data');
          } else {
            console.error('User document does not exist for UID:', user.uid);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },

    async fetchGlobalSettings({ commit }) {
      const db = getFirestore();
      const docRef = doc(db, Global_Settings_Collection, 'Global_Settings');

      try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          let data = docSnap.data();

          // Ensure all expected fields exist, fallback to defaults
          data = {
            Global_Settings_Shopper_Alert: data.Global_Settings_Shopper_Alert || {
              Message: '',
              Type: 'info',
              Show_Alert: true,
              Show_To_Verified_Shoppers: true,
              Show_To_Unverified_Shoppers: false,
              Show_To_Logged_In_Shoppers: true,
              Show_To_Logged_Out_Shoppers: true,
              Pages_To_Show: ['/deliver', '/account', '/account/requests'],
            },
            Global_Settings_Customer_Alert: data.Global_Settings_Customer_Alert || {
              Message: '',
              Type: 'info',
              Show_Alert: true,
              Show_To_Verified_Customers: true,
              Show_To_Unverified_Customers: true,
              Show_To_Logged_In_Customers: true,
              Show_To_Logged_Out_Customers: true,
              Pages_To_Show: ['/request', '/account/requests'],
            },
          };

          commit('Update_Global_Settings', data);
          console.log('Fetched Global Settings');
        } else {
          console.error('Global Settings document does not exist. Creating a new one...');

          const defaultSettings = {
            Global_Settings_ID: 'Global_Settings',
            Global_Settings_Created_At: serverTimestamp(),
            Global_Settings_Shopper_Alert: {
              Message: '',
              Type: 'info',
              Show_Alert: true,
              Show_To_Verified_Shoppers: true,
              Show_To_Unverified_Shoppers: false,
              Show_To_Logged_In_Shoppers: true,
              Show_To_Logged_Out_Shoppers: true,
              Pages_To_Show: ['/deliver', '/account', '/account/requests'],
            },
            Global_Settings_Customer_Alert: {
              Message: '',
              Type: 'info',
              Show_Alert: true,
              Show_To_Verified_Customers: true,
              Show_To_Unverified_Customers: true,
              Show_To_Logged_In_Customers: true,
              Show_To_Logged_Out_Customers: true,
              Pages_To_Show: ['/request', '/account/requests'],
            },
          };

          await setDoc(docRef, defaultSettings);
          commit('Update_Global_Settings', defaultSettings);
          console.log('Created default Global Settings document.');
        }
      } catch (error) {
        console.error('Error fetching or creating Global Settings:', error);
      }
    },

    Update_Selected_Option({ commit }, newData) {
      commit('Update_Selected_Option', newData);
    },

    Update_Selected_Object({ commit }, newData) {
      commit('Update_Selected_Object', newData);
    },

    Update_Global_Settings({ commit }, newData) {
      commit('Update_Global_Settings', newData);
    },
  },
  getters: {
    userData: (state) => state.userData,
    Global_Settings: (state) => state.Global_Settings,
  },
});
